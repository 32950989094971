import { PATH_DASHBOARD } from 'src/routes/paths';

export const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    id: 'principal',
    subheader: 'principal',
    items: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        path: PATH_DASHBOARD.dashboard.list,
        icon: 'material-symbols:analytics-rounded',
      },
      {
        id: 'rastreamento',
        title: 'Rastreamento',
        path: PATH_DASHBOARD.tracking.list,
        icon: 'wpf:gps-receiving',
      },
      {
        id: 'idletime',
        title: 'Controle de Paradas',
        path: PATH_DASHBOARD.idleTime.list,
        icon: 'mdi:timer-stop' /*info: <Label color="error">+32</Label>,*/,
      },
      {
        id: 'transittime',
        title: 'TransitTime',
        path: PATH_DASHBOARD.transittime.list,
        icon: 'wpf:in-transit',
      },
      {
        id: 'mensagem',
        title: 'Mensagem',
        path: PATH_DASHBOARD.message.list,
        icon: 'ic:baseline-message' /*info: <Label color="error">+32</Label>,*/,
      },
      {
        id: 'evento',
        title: 'Evento',
        path: PATH_DASHBOARD.event.list,
        icon: 'mdi:alert-outline' /*info: <Label color="error">+32</Label>,*/,
      },
      {
        id: 'ocorrencia',
        title: 'Ocorrência',
        path: PATH_DASHBOARD.occurrence.root,
        icon: 'carbon:report-data',
        children: [
          {
            id: 'consultar',
            title: 'Consultar',
            path: PATH_DASHBOARD.occurrence.list,
          },
          {
            id: 'cadastrar',
            title: 'Cadastrar',
            path: PATH_DASHBOARD.occurrence.insert,
          },
          {
            id: 'rel-ocorrencia',
            title: 'Relatório',
            path: PATH_DASHBOARD.occurrence.report,
          },
        ],
      },
    ],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    id: 'cadastros',
    subheader: 'cadastros',
    items: [
      {
        id: 'cliente',
        title: 'Cliente',
        path: PATH_DASHBOARD.client.root,
        icon: 'mdi:company',
        children: [
          {
            id: 'consultar',
            title: 'Consultar',
            path: PATH_DASHBOARD.client.list,
          },
          {
            id: 'cadastrar',
            title: 'Cadastrar',
            path: PATH_DASHBOARD.client.insert,
          },
        ],
      },
      {
        id: 'carreta',
        title: 'Carreta',
        path: PATH_DASHBOARD.trailer.root,
        icon: 'mdi:truck-trailer',
        children: [
          {
            id: 'consultar',
            title: 'Consultar',
            path: PATH_DASHBOARD.trailer.list,
          },
          {
            id: 'cadastrar',
            title: 'Cadastrar',
            path: PATH_DASHBOARD.trailer.insert,
          },
        ],
      },
      {
        id: 'contato',
        title: 'Contato',
        path: PATH_DASHBOARD.contact.root,
        icon: 'material-symbols:contact-phone-outline',
        children: [
          {
            id: 'consultar',
            title: 'Consultar',
            path: PATH_DASHBOARD.contact.list,
          },
          {
            id: 'cadastrar',
            title: 'Cadastrar',
            path: PATH_DASHBOARD.contact.insert,
          },
        ],
      },
      {
        id: 'grupo-de-contatos',
        title: 'Grupo de Contatos',
        path: PATH_DASHBOARD.contactGroup.root,
        icon: 'fluent-mdl2:connect-contacts',
        children: [
          {
            id: 'consultar',
            title: 'Consultar',
            path: PATH_DASHBOARD.contactGroup.list,
          },
          {
            id: 'cadastrar',
            title: 'Cadastrar',
            path: PATH_DASHBOARD.contactGroup.insert,
          },
        ],
      },
      {
        id: 'grupo-veiculos-usuarios',
        title: 'Grupo de Veículos e Usuários',
        path: PATH_DASHBOARD.group.root,
        icon: 'ic:outline-group-work',
        children: [
          {
            id: 'consultar',
            title: 'Consultar',
            path: PATH_DASHBOARD.group.list,
          },
          {
            id: 'cadastrar',
            title: 'Cadastrar',
            path: PATH_DASHBOARD.group.insert,
          },
        ],
      },
      {
        id: 'motorista',
        title: 'Motorista',
        path: PATH_DASHBOARD.driver.root,
        icon: 'healthicons:truck-driver-outline',
        children: [
          {
            id: 'consultar',
            title: 'Consultar',
            path: PATH_DASHBOARD.driver.list,
          },
          {
            id: 'cadastrar',
            title: 'Cadastrar',
            path: PATH_DASHBOARD.driver.insert,
          },
        ],
      },
      {
        id: 'rastreador',
        title: 'Rastreador',
        path: PATH_DASHBOARD.tracker.root,
        icon: 'mdi:cellphone-gps',
        children: [
          {
            id: 'consultar',
            title: 'Consultar',
            path: PATH_DASHBOARD.tracker.list,
          },
          {
            id: 'cadastrar',
            title: 'Cadastrar',
            path: PATH_DASHBOARD.tracker.insert,
          },
        ],
      },
      {
        id: 'usuario',
        title: 'Usuário',
        path: PATH_DASHBOARD.user.root,
        icon: 'ph:user-circle',
        children: [
          {
            id: 'consultar',
            title: 'Consultar',
            path: PATH_DASHBOARD.user.list,
          },
          {
            id: 'cadastrar',
            title: 'Cadastrar',
            path: PATH_DASHBOARD.user.insert,
          },
        ],
      },
      {
        id: 'veiculo',
        title: 'Veículo',
        path: PATH_DASHBOARD.vehicle.root,
        icon: 'fluent:vehicle-car-48-filled',
        children: [
          {
            id: 'consultar',
            title: 'Consultar',
            path: PATH_DASHBOARD.vehicle.list,
          },
          {
            id: 'cadastrar',
            title: 'Cadastrar',
            path: PATH_DASHBOARD.vehicle.insert,
          },
        ],
      },
      {
        id: 'viagem',
        title: 'Faixa de Temperatura',
        path: PATH_DASHBOARD.temperatureRange.root,
        icon: 'mdi:temperature-lines',
        children: [
          {
            id: 'faixa-temperatura',
            title: 'Consultar',
            path: PATH_DASHBOARD.temperatureRange.list,
          },
          {
            id: 'faixa-temperatura',
            title: 'Cadastrar',
            path: PATH_DASHBOARD.temperatureRange.insert,
          },
        ],
      },
    ],
  },

  // Map
  // ----------------------------------------------------------------------
  {
    id: 'mapa',
    subheader: 'mapa',
    items: [
      {
        id: 'cerca',
        title: 'Cerca Eletrônica',
        path: PATH_DASHBOARD.fence.root,
        icon: 'ph:polygon-bold',
        children: [
          {
            id: 'consultar',
            title: 'Consultar',
            path: PATH_DASHBOARD.fence.list,
          },
          {
            id: 'cadastrar',
            title: 'Cadastrar',
            path: PATH_DASHBOARD.fence.insert,
          },
        ],
      },
      {
        id: 'referencia',
        title: 'Referência',
        path: PATH_DASHBOARD.reference.root,
        icon: 'mdi:map-marker-multiple-outline',
        children: [
          {
            id: 'consultar',
            title: 'Consultar',
            path: PATH_DASHBOARD.reference.list,
          },
          {
            id: 'cadastrar',
            title: 'Cadastrar',
            path: PATH_DASHBOARD.reference.insert,
          },
        ],
      },
    ],
  },

  // ADM
  // ----------------------------------------------------------------------
  {
    id: 'adm',
    subheader: 'administração',
    items: [
      {
        id: 'nivel-acesso',
        title: 'Nível de Acesso',
        path: PATH_DASHBOARD.kindOfUser.root,
        icon: 'mdi:user-badge',
        children: [
          {
            id: 'consultar',
            title: 'Consultar',
            path: PATH_DASHBOARD.kindOfUser.list,
          },
          {
            id: 'cadastrar',
            title: 'Cadastrar',
            path: PATH_DASHBOARD.kindOfUser.insert,
          },
        ],
      },
      {
        id: 'grid',
        title: 'Grid',
        path: PATH_DASHBOARD.grid.root,
        icon: 'material-symbols:grid-view',
        children: [
          {
            id: 'consultar',
            title: 'Consultar',
            path: PATH_DASHBOARD.grid.list,
          },
          {
            id: 'cadastrar',
            title: 'Cadastrar',
            path: PATH_DASHBOARD.grid.insert,
          },
        ],
      },
      {
        id: 'pgr',
        title: 'PGR',
        path: PATH_DASHBOARD.riskManagement.root,
        icon: 'carbon:tree-fall-risk',
        children: [
          {
            id: 'consultar',
            title: 'Consultar',
            path: PATH_DASHBOARD.riskManagement.list,
          },
          {
            id: 'cadastrar',
            title: 'Cadastrar',
            path: PATH_DASHBOARD.riskManagement.insert,
          },
        ],
      },
      {
        id: 'seguradora',
        title: 'Seguradora',
        path: PATH_DASHBOARD.insurer.root,
        icon: 'healthicons:world-care-outline',
        children: [
          {
            id: 'consultar',
            title: 'Consultar',
            path: PATH_DASHBOARD.insurer.list,
          },
          {
            id: 'cadastrar',
            title: 'Cadastrar',
            path: PATH_DASHBOARD.insurer.insert,
          },
        ],
      },
      {
        id: 'apolice',
        title: 'Apólice de Seguros',
        path: PATH_DASHBOARD.insurance.root,
        icon: 'carbon:cabin-care',
        children: [
          {
            id: 'consultar',
            title: 'Consultar',
            path: PATH_DASHBOARD.insurance.list,
          },
          {
            id: 'cadastrar',
            title: 'Cadastrar',
            path: PATH_DASHBOARD.insurance.insert,
          },
        ],
      },
      {
        id: 'macro',
        title: 'Macro',
        path: PATH_DASHBOARD.macro.root,
        icon: 'ep:message-box',
        children: [
          {
            id: 'consultar',
            title: 'Consultar',
            path: PATH_DASHBOARD.macro.list,
          },
          {
            id: 'cadastrar',
            title: 'Cadastrar',
            path: PATH_DASHBOARD.macro.insert,
          },
        ],
      },
      {
        id: 'procedimento',
        title: 'Procedimento',
        path: PATH_DASHBOARD.procedure.root,
        icon: 'fluent-mdl2:set-action',
        children: [
          {
            id: 'consultar',
            title: 'Consultar',
            path: PATH_DASHBOARD.procedure.list,
          },
          {
            id: 'cadastrar',
            title: 'Cadastrar',
            path: PATH_DASHBOARD.procedure.insert,
          },
        ],
      },
      {
        id: 'sistema',
        title: 'Sistema de Rastreador',
        path: PATH_DASHBOARD.trackerSystem.list,
        icon: 'fluent:system-20-filled',
      },
      {
        id: 'tipos',
        title: 'Tipos',
        path: PATH_DASHBOARD.kinds.root,
        icon: 'fluent:scan-type-20-filled',
        children: [
          {
            id: 'tipocarroceria',
            title: 'Tipo de Carroceria',
            path: PATH_DASHBOARD.kindOfTruckBody.list,
          },
          {
            id: 'tipoevento',
            title: 'Tipo de Evento',
            path: PATH_DASHBOARD.kindOfEvent.list,
          },
          {
            id: 'tipoocorrencia',
            title: 'Tipo de Ocorrência',
            path: PATH_DASHBOARD.kindOfOccurrence.list,
          },
          {
            id: 'tipooperacao',
            title: 'Tipo de Operação',
            path: PATH_DASHBOARD.kindOfOperation.list,
          },
          {
            id: 'tiporeferencia',
            title: 'Tipo de Referência',
            path: PATH_DASHBOARD.kindOfMarker.list,
          },
          {
            id: 'tiporisco',
            title: 'Tipo de Risco',
            path: PATH_DASHBOARD.kindOfRisk.list,
          },
        ],
      },
      {
        id: 'bot',
        title: 'Bot',
        path: PATH_DASHBOARD.bot.root,
        icon: 'fluent:bot-24-regular',
        children: [
          {
            id: 'consultar',
            title: 'Consultar',
            path: PATH_DASHBOARD.bot.list,
          },
          {
            id: 'cadastrar',
            title: 'Cadastrar',
            path: PATH_DASHBOARD.bot.insert,
          },
        ],
      },
    ],
  },

  // Support
  // ----------------------------------------------------------------------
  {
    id: 'suporte',
    subheader: 'suporte',
    items: [
      {
        id: 'comando',
        title: 'Comando',
        path: PATH_DASHBOARD.command.root,
        icon: 'heroicons:command-line',
        children: [
          {
            id: 'consultar',
            title: 'Consultar',
            path: PATH_DASHBOARD.command.list,
          },
          // { id: 'cadastrar', title: 'Cadastrar', path: PATH_DASHBOARD.command.insert },
          {
            id: 'ultimos-comandos',
            title: 'Últimos Enviados',
            path: PATH_DASHBOARD.command.lastsent,
          },
        ],
      },
      {
        id: 'comando-generico',
        title: 'Comando Genérico',
        path: PATH_DASHBOARD.genericCommand.root,
        icon: 'fluent:key-command-20-filled',
        children: [
          {
            id: 'consultar',
            title: 'Consultar',
            path: PATH_DASHBOARD.genericCommand.list,
          },
          // { id: 'cadastrar', title: 'Cadastrar', path: PATH_DASHBOARD.genericCommand.insert },
        ],
      },
      {
        id: 'sistema-conta',
        title: 'Conta de Sistema',
        path: PATH_DASHBOARD.account.root,
        icon: 'material-symbols:account-tree-outline',
        children: [
          {
            id: 'consultar',
            title: 'Consultar',
            path: PATH_DASHBOARD.account.list,
          },
          {
            id: 'cadastrar',
            title: 'Cadastrar',
            path: PATH_DASHBOARD.account.insert,
          },
        ],
      },
      {
        id: 'sistema',
        title: 'Plataforma',
        path: PATH_DASHBOARD.platform.root,
        icon: 'game-icons:platform',
        children: [
          {
            id: 'consultar',
            title: 'Consultar',
            path: PATH_DASHBOARD.platform.list,
          },
          {
            id: 'cadastrar',
            title: 'Cadastrar',
            path: PATH_DASHBOARD.platform.insert,
          },
        ],
      },
      {
        id: 'map',
        title: 'Mapas',
        path: PATH_DASHBOARD.map.root,
        icon: 'material-symbols:map',
        children: [
          {
            id: 'markerViewer',
            title: 'Visualizadores de Marcadores',
            path: PATH_DASHBOARD.map.markerViewer,
          },
          {
            id: 'tripTester',
            title: 'Testador de Viagem',
            path: PATH_DASHBOARD.map.tripTester,
          },
        ],
      },
      {
        id: 'import',
        title: 'Import',
        path: PATH_DASHBOARD.import.root,
        icon: 'bx:import',
        children: [
          { id: 'upload', title: 'Upload', path: PATH_DASHBOARD.import.upload },
        ],
      },
      {
        id: 'test',
        title: 'Testes',
        path: PATH_DASHBOARD.test.root,
        icon: 'carbon:test-tool',
        children: [
          {
            id: 'test',
            title: 'Giga de Testes',
            path: PATH_DASHBOARD.tracker.testjig,
          },
        ],
      },
    ],
  },
];

export default navConfig;
